<template>
  <NewExpenseUser
    :EX="EX"
    :warehouses="warehouses"
    :countries="countries"
    :expenseTypes="expenseTypes"
    :banks="banks"
    :payees="payees"
    @save="save"
  />
</template>

<script>
  import NewExpenseUser from "./ExpenseEditingSection/ExpenseEditingSection";
  import {Expenses} from "../../models/Expenses";
  import {expenseMixin} from "../../../../../mixins/expenseMixins/expenseMixin";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "ExpenseEditing",
    components: {
      NewExpenseUser,
    },

    mixins: [expenseMixin],

    created() {
      this.$store.dispatch('getExpensesCreate').then(response => {

        let respData = this.getRespData(response)
        this.warehouses = respData.warehouses
        this.countries = respData.countries
        this.expenseTypes = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.DEFAULT_TYPE.value]
        this.banks = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.BANK_TYPE.value]
        this.payees = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.PAYEE_TYPE.value]

        this.$store.dispatch('getExpenses', this.$route.params.id).then(response => {
          this.item = this.getRespData(response)
          this.EX.setItem(this.item, this.warehouses, this.countries)


          let files = [
            ...this.item?.file_entities,
          ]

          this.downloadFileFromArray(files).then((documents) => {
            let filesObj = this.EX.data.Files
            filesObj.setDownloadFiles(documents)
          })

          // if(this.item.file_entities && this.item.file_entities.length > 0 && this.item.file_entities[0].extension !== 'pdf'){
          //   this.getFileFromServer(this._.find(this.item.file_entities, {type: 'small_image'}))
          // }
          //
          // if(this.item.file_entities && this.item.file_entities.length > 0 && this.item.file_entities[0].extension === 'pdf'){
          //   this.getFileFromServer(this._.find(this.item.file_entities, {extension: 'pdf'}))
          // }
        }).catch(error => this.createErrorLog(error))
      }).catch(error => this.createErrorLog(error))
    },

    data() {
      return {
        EX: new Expenses(),
        warehouses: [],
        countries: [],
        expenseTypes: [],
        banks: [],
        payees: [],
      }
    },

    methods: {
      async downloadFileFromArray(array) {
        let downloadedFilesArray = []
        for (const item of array) {
          if (item?.type === 'small_image') {
            await this.getFileFromServerReturnObject(item, 'image').then(document => {
              downloadedFilesArray.push(document)
            })
          }

          if (item?.extension === 'pdf') {
            await this.getFileFromServerReturnObject(item, 'pdf').then(document => {
              downloadedFilesArray.push(document)
            })
          }
        }
        return downloadedFilesArray
      },
    }

  }
</script>

<style scoped>

</style>
